import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {StaticImage} from "gatsby-plugin-image";
import {graphql} from 'gatsby';
import rearLatch from "../../../../images/shared/rear-units/deadlatch.png";
import rearBolt from "../../../../images/shared/rear-units/deadbolt.png";
import swatchBrushed from "../../../../images/shared/circle-brushed-nickle.png";
import swatchBlack from "../../../../images/shared/circle-matte-black.png";
import BANManagement from "../../../../components/pages/BANManagement";
import FaqSection from "../../../../components/pages/FaqSection";
import ProductDocuments from "../../../../components/products/ProductDocuments";


import iconKeypadStnd from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-stnd.svg";
import iconKeypadStndOn from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-stnd-on.svg";
import iconKeypadMini from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-mini.svg";
import iconKeypadMiniOn from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-mini-on.svg";
import iconKeypadMiniVert from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-mini-vert.svg";
import iconKeypadMiniVertOn
  from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-mini-vert-on.svg";
import iconKeypadVert from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-vert.svg";
import iconKeypadVertOn from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-vert-on.svg";
import iconKeypadHorizRight
  from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-horiz-right.svg";
import iconKeypadHorizRightOn
  from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-horiz-right-on.svg";
import iconKeypadHorizLeft from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-horiz-left.svg";
import iconKeypadHorizLeftOn
  from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-horiz-left-on.svg";
import iconKeypadMiniHorizLeft
  from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-mini-horiz-left.svg";
import iconKeypadMiniHorizLeftOn
  from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-mini-horiz-left-on.svg";
import iconKeypadMiniHorizRight
  from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-mini-horiz-right.svg";
import iconKeypadMiniHorizRightOn
  from "../../../../images/shared/lock-icons/aspire/keypad/icon-aspire-keypad-mini-horiz-right-on.svg";

import iconRfidStnd from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-stnd.svg";
import iconRfidStndOn from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-stnd-on.svg";
import iconRfidMini from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-mini.svg";
import iconRfidMiniOn from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-mini-on.svg";
import iconRfidMiniVert from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-mini-vert.svg";
import iconRfidMiniVertOn from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-mini-vert-on.svg";
import iconRfidVert from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-vert.svg";
import iconRfidVertOn from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-vert-on.svg";

import iconRfidHorizMiniLeft from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-horiz-left.svg";
import iconRfidHorizMiniLeftOn
  from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-horiz-left-on.svg";
import iconRfidHorizMiniRight from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-horiz-right.svg";
import iconRfidHorizMiniRightOn
  from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-horiz-right-on.svg";
import iconRfidHorizPullLeft
  from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-horiz-pull-left.svg";
import iconRfidHorizPullLeftOn
  from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-horiz-pull-left-on.svg";
import iconRfidHorizPullRight
  from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-horiz-pull-right.svg";
import iconRfidHorizPullRightOn
  from "../../../../images/shared/lock-icons/aspire/rfid/icon-aspire-rfid-horiz-pull-right-on.svg";

import iconSmartKeyStandard
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-stnd-n.svg";
import iconSmartKeyStandardOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-stnd-n-on.svg";
import iconSmartKeyStandardMini
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-stnd-mini-n.svg";
import iconSmartKeyStandardMiniOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-stnd-mini-n-on.svg";
import iconSmartKeyVertical
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-vert-n.svg";
import iconSmartKeyVerticalOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-vert-n-on.svg";
import iconSmartKeyVerticalPull
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-vert-pull-n.svg";
import iconSmartKeyVerticalPullOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-vert-pull-n-on.svg";
import iconSmartKeyHorizLeft
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-horz-left-n.svg";
import iconSmartKeyHorizLeftOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-horz-left-n-on.svg";
import iconSmartKeyHorizRight
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-horz-right-n.svg";
import iconSmartKeyHorizRightOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-horz-right-n-on.svg";
import iconSmartKeyHorizRightPull
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-horz-right-pull-n.svg";
import iconSmartKeyHorizRightPullOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-horz-right-pull-n-on.svg";
import iconSmartKeyHorizLeftPull
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-horz-left-pull-n.svg";
import iconSmartKeyHorizLeftPullOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/keypad/icon-aspire-keypad-horz-left-pull-n-on.svg";

import iconSmartRfidStandard
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-stnd-n.svg";
import iconSmartRfidStandardOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-stnd-n-on.svg";
import iconSmartRfidStandardMini
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-stnd-mini-n.svg";
import iconSmartRfidStandardMiniOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-stnd-mini-n-on.svg";
import iconSmartRfidVertical
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-vert-n.svg";
import iconSmartRfidVerticalOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-vert-n-on.svg";
import iconSmartRfidVerticalPull
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-vert-pull-n.svg";
import iconSmartRfidVerticalPullOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-vert-pull-n-on.svg";
import iconSmartRfidHorizLeft
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-horz-left-n.svg";
import iconSmartRfidHorizLeftOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-horz-left-n-on.svg";
import iconSmartRfidHorizRight
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-horz-right-n.svg";
import iconSmartRfidHorizRightOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-horz-right-n-on.svg";
import iconSmartRfidHorizRightPull
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-horz-right-pull-n.svg";
import iconSmartRfidHorizRightPullOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-horz-right-pull-n-on.svg";
import iconSmartRfidHorizLeftPull
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-horz-left-pull-n.svg";
import iconSmartRfidHorizLeftPullOn
  from "../../../../images/shared/lock-icons/aspire/smartphone/rfid/icon-aspire-rfid-horz-left-pull-n-on.svg";


import BookConsultation from "../../../../components/pages/BookConsultation";

const IndexPage = () => {

  const {t} = useTranslation();

  const [kpOne, setKpOne] = useState(true);
  const [kpTwo, setKpTwo] = useState(false);
  const [kpThree, setKpThree] = useState(false);
  const [kpFour, setKpFour] = useState(false);
  const [kpHorizMiniLeft, setKpHorizMiniLeft] = useState(false);
  const [kpHorizMiniRight, setKpHorizMiniRight] = useState(false);
  const [kpFive, setKpFive] = useState(false);
  const [kpSix, setKpSix] = useState(false);

  const [rfidOne, setRfidOne] = useState(false);
  const [rfidTwo, setRfidTwo] = useState(false);
  const [rfidThree, setRfidThree] = useState(false);
  const [rfidFour, setRfidFour] = useState(false);
  const [rfidFive, setRfidFive] = useState(false);
  const [rfidSix, setRfidSix] = useState(false);
  const [rfidSeven, setRfidSeven] = useState(false);
  const [rfidEight, setRfidEight] = useState(false);

  const [spKeypadOne, setSpKeypadOne] = useState(false);
  const [spKeypadTwo, setSpKeypadTwo] = useState(false);
  const [spKeypadThree, setSpKeypadThree] = useState(false);
  const [spKeypadFour, setSpKeypadFour] = useState(false);
  const [spKeypadFive, setSpKeypadFive] = useState(false);
  const [spKeypadSix, setSpKeypadSix] = useState(false);
  const [spKeypadSeven, setSpKeypadSeven] = useState(false);
  const [spKeypadEight, setSpKeypadEight] = useState(false);

  const [spRfidOne, setSpRfidOne] = useState(false);
  const [spRfidTwo, setSpRfidTwo] = useState(false);
  const [spRfidThree, setSpRfidThree] = useState(false);
  const [spRfidFour, setSpRfidFour] = useState(false);
  const [spRfidFive, setSpRfidFive] = useState(false);
  const [spRfidSix, setSpRfidSix] = useState(false);
  const [spRfidSeven, setSpRfidSeven] = useState(false);
  const [spRfidEight, setSpRfidEight] = useState(false);

  // Lock Labels
  let keypadLabels = [
    t('aspire_key_labels_1'),
    t('aspire_key_labels_2'),
    t('aspire_key_labels_3'),
    t('aspire_key_labels_4'),
    t('aspire_key_labels_5'),
    t('aspire_key_labels_6'),
    t('aspire_key_labels_7'),
    t('aspire_key_labels_8')
  ];
  let smartKeypadLabels = [
    t('aspire_smartkey_labels_1'),
    t('aspire_smartkey_labels_2'),
    t('aspire_smartkey_labels_3'),
    t('aspire_smartkey_labels_4'),
    t('aspire_smartkey_labels_5'),
    t('aspire_smartkey_labels_6'),
    t('aspire_smartkey_labels_7'),
    t('aspire_smartkey_labels_8')
  ];
  let rfidLabels = [
    t('aspire_rfid_labels_1'),
    t('aspire_rfid_labels_2'),
    t('aspire_rfid_labels_3'),
    t('aspire_rfid_labels_4'),
    t('aspire_rfid_labels_5'),
    t('aspire_rfid_labels_6'),
    t('aspire_rfid_labels_7'),
    t('aspire_rfid_labels_8'),
  ];
  let smartRfidLabels = [
    t('aspire_smartrfid_labels_1'),
    t('aspire_smartrfid_labels_2'),
    t('aspire_smartrfid_labels_3'),
    t('aspire_smartrfid_labels_4'),
    t('aspire_smartrfid_labels_5'),
    t('aspire_smartrfid_labels_6'),
    t('aspire_smartrfid_labels_7'),
    t('aspire_smartrfid_labels_8')
  ];
  const [bodyStyleLabel, setBodyStyleLabel] = useState(keypadLabels[0]);

  //Interface choices
  const [isRfidSelected, setIsRfidSelected] = useState(false);
  const [isKeypadSelected, setIsKeypadSelected] = useState(true);
  const [isSmartphoneKeypadSelected, setIsSmartphoneKeypadSelected] = useState(false);
  const [isSmartphoneRfidSelected, setIsSmartphoneRfidSelected] = useState(false);

  let aspireBrochures = [
    'https://cdn.mediavalet.com/usva/digilock/8uwz6GJavEGGlt0IN1OdiQ/Nb_2vosLMEqLFJ_-I1ER7A/Original/DIGILOCK-ASPIRE-RFID-PRODUCT-BROCHURE.pdf',
    'https://cdn.mediavalet.com/usva/digilock/886y_0z8UUK0Yb-zq60aEQ/EwbT22sZsUmw99-JUaJ4UA/Original/DIGILOCK-ASPIRE-KEYPAD-PRODUCT-BROCHURE.pdf',
    'https://cdn.mediavalet.com/usva/digilock/QklEN7AwqUGIU-wuVVHHrg/OGSQ4yR5pkGmX9rLG1LmIA/Original/DIGILOCK-ASPIRE-KEYPAD-NETWORK-PRODUCT-BROCHURE.pdf',
    'https://cdn.mediavalet.com/usva/digilock/XJp-76OMPUimAMRI5kQByw/PT9ofOYwX0qHVyPmkLV5Ww/Original/DIGILOCK-ASPIRE-RFID-NETWORK-PRODUCT-BROCHURE.pdf'
  ];

  // Datasheets
  let dsAspireKeypadStandardSmart = 'https://cdn.mediavalet.com/usva/digilock/90Tw605vPkuw2T-AhJylRQ/9Zo1RAhOSUawUXnJ3-VUxA/Original/DS-D6AKN-S-Bolt-Cable-UEN.pdf';
  let dsAspireKeypadVerticalSmart = 'https://cdn.mediavalet.com/usva/digilock/hzp27OuOf0Wo4CpR-x1frg/vuMkdw9Rb0CGppSjz2nHaQ/Original/DS-D6AKN-V-Bolt-Cable-UEN.pdf';
  let dsAspireKeypadHorizontalSmart = 'https://cdn.mediavalet.com/usva/digilock/LPTeTcp35k2p6OlSRJHyzg/wM2-FxtPqEuIzEmX6C3WCQ/Original/DS-D6AKN-H-Bolt-Cable-UEN.pdf';

  let dsAspireRFIDNarrowSmart = 'https://cdn.mediavalet.com/usva/digilock/9oknvPik906JLHoW03_vGg/1fjzRmp9a0Smy0euC91dmw/Original/DS-D6ARN-N-Bolt-Cable-DEN.pdf';
  let dsAspireRFIDStandardSmart = 'https://cdn.mediavalet.com/usva/digilock/47KBEzcJTkOLQbFsr-1hoQ/URpZ7NJXLke01G8o5Dp0wQ/Original/DS-D6ARN-S-Bolt-Cable-DEN.pdf';

  let dsAspireRFIDNarrow = 'https://cdn.mediavalet.com/usva/digilock/6x8T9OGpvEKbo1_JeZMBdQ/sZtz-CsAvU-GSjlluHzosQ/Original/DS-D6AR-N-Bolt-Cable-DEN.pdf';
  let dsAspireRFIDStandard = 'https://cdn.mediavalet.com/usva/digilock/Sfx2jh2b7kmy7YaxAXoD9g/9lVxFFjlrkG8a0DY7gaDKw/Original/DS-D6AR-S-Bolt-Cable-UEN.pdf';

  let dsAspireKeypadStandard = 'https://cdn.mediavalet.com/usva/digilock/OuZglK8G1UCJD1lYj4x6sA/Ex8f9nyPYUOtWTqlj2x48w/Original/DS-D6AK-S-Bolt-Cable-UEN.pdf';
  let dsAspireKeypadVertical = 'https://cdn.mediavalet.com/usva/digilock/hZq6dhtM9kSk4UElhf0IOQ/3BjvLh9jeES7ZTaZdaN5MQ/Original/DS-D6AK-V-Bolt-Cable-UEN.pdf';
  let dsAspireKeypadHorizontal = 'https://cdn.mediavalet.com/usva/digilock/gr9PaAGyrE-2UjkDRq2nxA/Qgbc3UR83EOK3R2xT5_wPw/Original/DS-D6AK-H-Bolt-Cable-UEN.pdf';

  const [brochure, setBrochure] = useState(aspireBrochures[1]);
  const [dataSheet, setDataSheet] = useState(dsAspireKeypadStandard);


  const resetLockImages = () => {
    //keypad
    setKpOne(false);
    setKpTwo(false);
    setKpThree(false);
    setKpFour(false);
    setKpHorizMiniLeft(false);
    setKpHorizMiniRight(false);
    setKpFive(false);
    setKpSix(false);
    //rfid
    setRfidOne(false);
    setRfidTwo(false);
    setRfidThree(false);
    setRfidFour(false);
    setRfidFive(false);
    setRfidSix(false);
    setRfidSeven(false);
    setRfidEight(false);
    //smart key
    setSpKeypadOne(false);
    setSpKeypadTwo(false);
    setSpKeypadThree(false);
    setSpKeypadFour(false);
    setSpKeypadFive(false);
    setSpKeypadSix(false);
    setSpKeypadSeven(false);
    setSpKeypadEight(false);
    //smart rfid
    setSpRfidOne(false);
    setSpRfidTwo(false);
    setSpRfidThree(false);
    setSpRfidFour(false);
    setSpRfidFive(false);
    setSpRfidSix(false);
    setSpRfidSeven(false);
    setSpRfidEight(false);
  }

  const resetInterfaces = () => {
    setIsRfidSelected(false);
    setIsSmartphoneKeypadSelected(false);
    setIsSmartphoneRfidSelected(false);
    setIsKeypadSelected(false);
  }
  const handleInterface = (e) => {
    let data = e.target.getAttribute('data-value');
    resetLockImages();
    resetInterfaces();
    switch (data) {
      case 'rfid':
        setIsRfidSelected(true);
        setRfidOne(true);
        setBodyStyleLabel(rfidLabels[0]);
        setBrochure(aspireBrochures[0]);
        setDataSheet(dsAspireRFIDStandardSmart);
        break;
      case 'keypad':
        setIsKeypadSelected(true);
        setKpOne(true);
        setBodyStyleLabel(keypadLabels[0]);
        setBrochure(aspireBrochures[1]);
        setDataSheet(dsAspireKeypadStandardSmart);

        break;
      case 'smartphone-keypad':
        setIsSmartphoneKeypadSelected(true);
        setSpKeypadOne(true);
        setBodyStyleLabel(smartKeypadLabels[0]);
        setBrochure(aspireBrochures[2]);
        setDataSheet(dsAspireKeypadStandardSmart);
        break;
      default: //smartphone rfid
        setIsSmartphoneRfidSelected(true);
        setSpRfidOne(true);
        setBodyStyleLabel(smartRfidLabels[0]);
        setBrochure(aspireBrochures[3]);
        setDataSheet(dsAspireRFIDStandardSmart);
    }
  }
  const handleKeypadIconClick = (e) => {
    resetLockImages();

    let data = e.target.getAttribute('data-value');
    switch (data) {
      case 'kp-one':
        setKpOne(true);
        setBodyStyleLabel(keypadLabels[0]);
        setDataSheet(dsAspireKeypadStandard);
        break;
      case 'kp-two':
        setKpTwo(true);
        setBodyStyleLabel(keypadLabels[1]);
        setDataSheet(dsAspireKeypadStandard);
        break;
      case 'kp-three':
        setKpThree(true);
        setBodyStyleLabel(keypadLabels[2]);
        setDataSheet(dsAspireKeypadVertical);
        break;
      case 'kp-four':
        setKpFour(true);
        setBodyStyleLabel(keypadLabels[3]);
        setDataSheet(dsAspireKeypadVertical);
        break;
      case 'kp-kpHorizMiniLeft':
        setKpHorizMiniLeft(true);
        setBodyStyleLabel(keypadLabels[6]);
        setDataSheet(dsAspireKeypadHorizontal);
        break;
      case 'kp-kpHorizMiniRight':
        setKpHorizMiniRight(true);
        setBodyStyleLabel(keypadLabels[7]);
        setDataSheet(dsAspireKeypadHorizontal);
        break;
      case 'kp-five':
        setKpFive(true);
        setBodyStyleLabel(keypadLabels[4]);
        setDataSheet(dsAspireKeypadHorizontal);
        break;
      case 'kp-six':
        setKpSix(true);
        setBodyStyleLabel(keypadLabels[5]);
        setDataSheet(dsAspireKeypadHorizontal);
    }
  }
  const handleRfidIconClick = (e) => {
    resetLockImages();
    let data = e.target.getAttribute('data-value');
    switch (data) {
      case 'rfid-one':
        setRfidOne(true);
        setBodyStyleLabel(rfidLabels[0]);
        setDataSheet(dsAspireRFIDStandard);
        break;
      case 'rfid-two':
        setRfidTwo(true);
        setBodyStyleLabel(rfidLabels[1]);
        setDataSheet(dsAspireRFIDStandard);
        break;
      case 'rfid-three':
        setRfidThree(true);
        setBodyStyleLabel(rfidLabels[2]);
        setDataSheet(dsAspireRFIDNarrow);
        break;
      case 'rfid-four':
        setRfidFour(true);
        setBodyStyleLabel(rfidLabels[3]);
        setDataSheet(dsAspireRFIDNarrow);
        break;
      case 'rfid-five':
        setRfidFive(true);
        setBodyStyleLabel(rfidLabels[4]);
        setDataSheet(dsAspireRFIDNarrowSmart);
        break;
      case 'rfid-six':
        setRfidSix(true);
        setBodyStyleLabel(rfidLabels[5]);
        setDataSheet(dsAspireRFIDNarrowSmart);
        break;
      case 'rfid-seven':
        setRfidSeven(true);
        setBodyStyleLabel(rfidLabels[6]);
        setDataSheet(dsAspireRFIDNarrowSmart);
        break;
      case 'rfid-eight':
        setRfidEight(true);
        setBodyStyleLabel(rfidLabels[7]);
        setDataSheet(dsAspireRFIDNarrowSmart);
    }
  }
  const handleSmartKeypadIconClick = (e) => {
    resetLockImages();
    let data = e.target.getAttribute('data-value');
    switch (data) {
      case 'sp-kp-one':
        setSpKeypadOne(true);
        setBodyStyleLabel(smartKeypadLabels[0]);
        setDataSheet(dsAspireKeypadStandardSmart);
        break;
      case 'sp-kp-two':
        setSpKeypadTwo(true);
        setBodyStyleLabel(smartKeypadLabels[1]);
        setDataSheet(dsAspireKeypadStandardSmart);
        break;
      case 'sp-kp-three':
        setSpKeypadThree(true);
        setBodyStyleLabel(smartKeypadLabels[2]);
        setDataSheet(dsAspireKeypadVerticalSmart);
        break;
      case 'sp-kp-four':
        setSpKeypadFour(true);
        setBodyStyleLabel(smartKeypadLabels[3]);
        setDataSheet(dsAspireKeypadVerticalSmart);
        break;
      case 'sp-kp-five':
        setSpKeypadFive(true);
        setBodyStyleLabel(smartKeypadLabels[4]);
        setDataSheet(dsAspireKeypadHorizontalSmart);
        break;
      case 'sp-kp-six':
        setSpKeypadSix(true);
        setBodyStyleLabel(smartKeypadLabels[5]);
        setDataSheet(dsAspireKeypadHorizontalSmart);
        break;
      case 'sp-kp-seven':
        setSpKeypadSeven(true);
        setBodyStyleLabel(smartKeypadLabels[6]);
        setDataSheet(dsAspireKeypadHorizontalSmart);
        break;
      case 'sp-kp-eight':
        setSpKeypadEight(true);
        setBodyStyleLabel(smartKeypadLabels[7]);
        setDataSheet(dsAspireKeypadHorizontalSmart);
    }
  }
  const handleSmartRfidIconClick = (e) => {
    resetLockImages();
    let data = e.target.getAttribute('data-value');
    switch (data) {
      case 'sp-rfid-one':
        setSpRfidOne(true);
        setBodyStyleLabel(smartRfidLabels[0]);
        setDataSheet(dsAspireRFIDStandardSmart);
        break;
      case 'sp-rfid-two':
        setSpRfidTwo(true);
        setBodyStyleLabel(smartRfidLabels[1]);
        setDataSheet(dsAspireRFIDStandardSmart);
        break;
      case 'sp-rfid-three':
        setSpRfidThree(true);
        setBodyStyleLabel(smartRfidLabels[2]);
        setDataSheet(dsAspireRFIDNarrowSmart);
        break;
      case 'sp-rfid-four':
        setSpRfidFour(true);
        setBodyStyleLabel(smartRfidLabels[3]);
        setDataSheet(dsAspireRFIDNarrowSmart);
        break;
      case 'sp-rfid-five':
        setSpRfidFive(true);
        setBodyStyleLabel(smartRfidLabels[4]);
        setDataSheet(dsAspireRFIDNarrowSmart);
        break;
      case 'sp-rfid-six':
        setSpRfidSix(true);
        setBodyStyleLabel(smartRfidLabels[5]);
        setDataSheet(dsAspireRFIDNarrowSmart);
        break;
      case 'sp-rfid-seven':
        setSpRfidSeven(true);
        setBodyStyleLabel(smartRfidLabels[6]);
        setDataSheet(dsAspireRFIDNarrowSmart);
        break;
      case 'sp-rfid-eight':
        setSpRfidEight(true);
        setBodyStyleLabel(smartRfidLabels[7]);
        setDataSheet(dsAspireRFIDNarrowSmart);
    }
  }
  const handleSmartphoneClick = (e) => {
    //code
  }

  useEffect(() => {
    // code
  }, [])

  return (
      <Layout>
        <Seo
            title={t('aspire_title_tag')}
            description={t('aspire_desc')}
        />
        <div className="products aspire-page">
          <section className="hero-pages">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/aspire/logo-gray-aspire.png"
                        loading={'lazy'}
                        width={191}
                        height={65}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Aspire logo"
                    />
                    <h1>
                      <Trans>aspire_headline</Trans>
                    </h1>
                    <p>
                      <Trans>
                        aspire_headline_copy
                      </Trans>
                    </p>
                    <BookConsultation/>
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/aspire/aspire-family.png"
                        loading={'lazy'}
                        width={443}
                        height={336}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Aspire famiily"
                        className={'cleo-family'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br/>
          </section>
          <div id="pivot" class="section-delimeter container"/>
          <section className={'appearance-functionality'}>
            <div className="container">
              <h1 className={'headline'} dangerouslySetInnerHTML={{__html: t('aspire_access_lock')}}/>
              <div className="grid-two-col">
                <div className="grid-two-col-item-single center locks-container">
                  <div className="locks revealer aspire">
                    <menu className="interfaces">
                      <button
                          data-value={'rfid'}
                          onClick={handleInterface}
                          className={isRfidSelected ? 'selected' : ''}>
                        RFID<span/>
                      </button>
                      <button
                          data-value={'keypad'}
                          onClick={handleInterface}
                          className={isKeypadSelected ? 'selected' : ''}>
                        Keypad<span/>
                      </button>
                      <button
                          data-value={'smartphone-keypad'}
                          onClick={handleInterface}
                          className={isSmartphoneKeypadSelected ? 'selected' : ''}>
                        Smartphone/<br/>Keypad<span/>
                      </button>
                      <button
                          data-value={'smartphone-rfid'}
                          onClick={handleInterface}
                          className={isSmartphoneRfidSelected ? 'selected' : ''}>
                        Smartphone/<br/>RFID<span/>
                      </button>
                    </menu>
                    <div className={isKeypadSelected ? 'icons show' : 'icons'}>
                      {/*Keypad*/}
                      <div>
                        <img src={kpOne ? iconKeypadStndOn : iconKeypadStnd}
                             className={isKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleKeypadIconClick}
                             data-value={'kp-one'}
                             width={'27'}
                             height={'49'}
                             alt="keypad standard"/>
                      </div>
                      <div>
                        <img src={kpTwo ? iconKeypadMiniOn : iconKeypadMini}
                             className={isKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleKeypadIconClick}
                             data-value={'kp-two'}
                             width={'29'}
                             height={'36'}
                             alt="keypad mini"/>
                      </div>
                      <div>
                        <img src={kpThree ? iconKeypadMiniVertOn : iconKeypadMiniVert}
                             className={isKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleKeypadIconClick}
                             data-value={'kp-three'}
                             width={'20'}
                             height={'49'}
                             alt="keypad mini vertical"/>
                      </div>
                      <div>
                        <img src={kpFour ? iconKeypadVertOn : iconKeypadVert}
                             className={isKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleKeypadIconClick}
                             data-value={'kp-four'}
                             width={'20'}
                             height={'60'}
                             alt="keypad vertical"/>
                      </div>
                      <div>
                        <img src={kpFive ? iconKeypadMiniHorizLeftOn : iconKeypadMiniHorizLeft}
                             className={isKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleKeypadIconClick}
                             data-value={'kp-five'}
                             width={'49'}
                             height={'20'}
                             alt="keypad vertical"/>
                      </div>
                      <div>
                        <img src={kpSix ? iconKeypadMiniHorizRightOn : iconKeypadMiniHorizRight}
                             className={isKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleKeypadIconClick}
                             data-value={'kp-six'}
                             width={'49'}
                             height={'20'}
                             alt="keypad vertical"/>
                      </div>
                      <div>
                        <img src={kpHorizMiniLeft ? iconKeypadHorizLeftOn : iconKeypadHorizLeft}
                             className={isKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleKeypadIconClick}
                             data-value={'kp-kpHorizMiniLeft'}
                             width={'60'}
                             height={'20'}
                             alt="keypad horizontal right"/>
                      </div>
                      <div>
                        <img src={kpHorizMiniRight ? iconKeypadHorizRightOn : iconKeypadHorizRight}
                             className={isKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleKeypadIconClick}
                             data-value={'kp-kpHorizMiniRight'}
                             width={'60'}
                             height={'20'}
                             alt="keypad horizontal left"/>
                      </div>
                    </div>
                    <div className={isRfidSelected ? 'icons show' : 'icons'}>
                      <div>
                        <img src={rfidOne ? iconRfidStndOn : iconRfidStnd}
                             className={isRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleRfidIconClick}
                             data-value={'rfid-one'}
                             width={'29'}
                             height={'49'}
                             alt="rfid standard"/>
                      </div>
                      <div>
                        <img src={rfidTwo ? iconRfidMiniOn : iconRfidMini}
                             className={isRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleRfidIconClick}
                             data-value={'rfid-two'}
                             width={'29'}
                             height={'36'}
                             alt="rfid mini"/>
                      </div>
                      <div>
                        <img src={rfidThree ? iconRfidMiniVertOn : iconRfidMiniVert}
                             className={isRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleRfidIconClick}
                             data-value={'rfid-three'}
                             width={'20'}
                             height={'49'}
                             alt="rfid mini vertical"/>
                      </div>
                      <div>
                        <img src={rfidFour ? iconRfidVertOn : iconRfidVert}
                             className={isRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleRfidIconClick}
                             data-value={'rfid-four'}
                             width={'20'}
                             height={'60'}
                             alt="rfid vertical"/>
                      </div>
                      <div>
                        <img src={rfidFive ? iconRfidHorizMiniLeftOn : iconRfidHorizMiniLeft}
                             className={isRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleRfidIconClick}
                             data-value={'rfid-five'}
                             width={'45'}
                             height={'20'}
                             alt="rfid vertical"/>
                      </div>
                      <div>
                        <img src={rfidSix ? iconRfidHorizMiniRightOn : iconRfidHorizMiniRight}
                             className={isRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleRfidIconClick}
                             data-value={'rfid-six'}
                             width={'45'}
                             height={'18'}
                             alt="rfid vertical"/>
                      </div>
                      <div>
                        <img src={rfidSeven ? iconRfidHorizPullLeftOn : iconRfidHorizPullLeft}
                             className={isRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleRfidIconClick}
                             data-value={'rfid-seven'}
                             width={'57'}
                             height={'18'}
                             alt="rfid vertical"/>
                      </div>
                      <div>
                        <img src={rfidEight ? iconRfidHorizPullRightOn : iconRfidHorizPullRight}
                             className={isRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleRfidIconClick}
                             data-value={'rfid-eight'}
                             width={'57'}
                             height={'18'}
                             alt="rfid vertical"/>
                      </div>
                    </div>
                    <div className={isSmartphoneKeypadSelected ? 'icons show' : 'icons'}>
                      {/*Keypad*/}
                      <div>
                        <img src={spKeypadOne ? iconSmartKeyStandardOn : iconSmartKeyStandard}
                             className={isSmartphoneKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartKeypadIconClick}
                             data-value={'sp-kp-one'}
                             width={'29'}
                             height={'56'}
                             alt="keypad standard"/>
                      </div>
                      <div>
                        <img src={spKeypadTwo ? iconSmartKeyStandardMiniOn : iconSmartKeyStandardMini}
                             className={isSmartphoneKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartKeypadIconClick}
                             data-value={'sp-kp-two'}
                             width={'28'}
                             height={'43'}
                             alt="keypad mini"/>
                      </div>
                      <div>
                        <img src={spKeypadThree ? iconSmartKeyVerticalOn : iconSmartKeyVertical}
                             className={isSmartphoneKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartKeypadIconClick}
                             data-value={'sp-kp-three'}
                             width={'19'}
                             height={'52'}
                             alt="keypad mini vertical"/>
                      </div>
                      <div>
                        <img src={spKeypadFour ? iconSmartKeyVerticalPullOn : iconSmartKeyVerticalPull}
                             className={isSmartphoneKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartKeypadIconClick}
                             data-value={'sp-kp-four'}
                             width={'19'}
                             height={'64'}
                             alt="keypad vertical"/>
                      </div>
                      <div>
                        <img src={spKeypadFive ? iconSmartKeyHorizLeftOn : iconSmartKeyHorizLeft}
                             className={isSmartphoneKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartKeypadIconClick}
                             data-value={'sp-kp-five'}
                             width={'51'}
                             height={'20'}
                             alt="keypad vertical"/>
                      </div>
                      <div>
                        <img src={spKeypadSix ? iconSmartKeyHorizRightOn : iconSmartKeyHorizRight}
                             className={isSmartphoneKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartKeypadIconClick}
                             data-value={'sp-kp-six'}
                             width={'51'}
                             height={'20'}
                             alt="keypad vertical"/>
                      </div>
                      <div>
                        <img src={spKeypadSeven ? iconSmartKeyHorizLeftPullOn : iconSmartKeyHorizLeftPull}
                             className={isSmartphoneKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartKeypadIconClick}
                             data-value={'sp-kp-seven'}
                             width={'63'}
                             height={'19'}
                             alt="keypad horizontal left"/>
                      </div>
                      <div>
                        <img src={spKeypadEight ? iconSmartKeyHorizRightPullOn : iconSmartKeyHorizRightPull}
                             className={isSmartphoneKeypadSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartKeypadIconClick}
                             data-value={'sp-kp-eight'}
                             width={'64'}
                             height={'19'}
                             alt="keypad horizontal right"/>
                      </div>
                    </div>
                    <div className={isSmartphoneRfidSelected ? 'icons show' : 'icons'}>
                      <div>
                        <img src={spRfidOne ? iconSmartRfidStandardOn : iconSmartRfidStandard}
                             className={isSmartphoneRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartRfidIconClick}
                             data-value={'sp-rfid-one'}
                             width={'29'}
                             height={'56'}
                             alt="rfid standard"/>
                      </div>
                      <div>
                        <img src={spRfidTwo ? iconSmartRfidStandardMiniOn : iconSmartRfidStandardMini}
                             className={isSmartphoneRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartRfidIconClick}
                             data-value={'sp-rfid-two'}
                             width={'28'}
                             height={'43'}
                             alt="rfid mini"/>
                      </div>
                      <div>
                        <img src={spRfidThree ? iconSmartRfidVerticalOn : iconSmartRfidVertical}
                             className={isSmartphoneRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartRfidIconClick}
                             data-value={'sp-rfid-three'}
                             width={'19'}
                             height={'52'}
                             alt="rfid mini vertical"/>
                      </div>
                      <div>
                        <img src={spRfidFour ? iconSmartRfidVerticalPullOn : iconSmartRfidVerticalPull}
                             className={isSmartphoneRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartRfidIconClick}
                             data-value={'sp-rfid-four'}
                             width={'19'}
                             height={'64'}
                             alt="rfid vertical"/>
                      </div>
                      <div>
                        <img src={spRfidFive ? iconSmartRfidHorizLeftOn : iconSmartRfidHorizLeft}
                             className={isSmartphoneRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartRfidIconClick}
                             data-value={'sp-rfid-five'}
                             width={'51'}
                             height={'19'}
                             alt="rfid vertical"/>
                      </div>
                      <div>
                        <img src={spRfidSix ? iconSmartRfidHorizRightOn : iconSmartRfidHorizRight}
                             className={isSmartphoneRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartRfidIconClick}
                             data-value={'sp-rfid-six'}
                             width={'52'}
                             height={'19'}
                             alt="rfid vertical"/>
                      </div>
                      <div>
                        <img src={spRfidEight ? iconSmartRfidHorizLeftPullOn : iconSmartRfidHorizLeftPull}
                             className={isSmartphoneRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartRfidIconClick}
                             data-value={'sp-rfid-seven'}
                             width={'64'}
                             height={'19'}
                             alt="rfid vertical"/>
                      </div>
                      <div>
                        <img src={spRfidSeven ? iconSmartRfidHorizRightPullOn : iconSmartRfidHorizRightPull}
                             className={isSmartphoneRfidSelected ? 'lock-icon selected' : 'lock-icon hide'}
                             onClick={handleSmartRfidIconClick}
                             data-value={'sp-rfid-eight'}
                             width={'63'}
                             height={'19'}
                             alt="rfid vertical"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-two-col-item-single center locks-container">
                  <div className="locks">
                    <div className={'body-locks-container'}>
                      <StaticImage
                          className={kpOne ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-stnd.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={kpTwo ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-stnd-small.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={kpThree ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-vert-small.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={kpFour ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-vert.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={kpFive ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-horiz-no-pull-left.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={kpSix ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-horiz-no-pull-right.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={kpHorizMiniLeft ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-orient-horiz-left.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={kpHorizMiniRight ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-orient-horiz-right.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={rfidOne ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-rfid.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />

                      <StaticImage
                          className={rfidTwo ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-rfid-small.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />

                      <StaticImage
                          className={rfidThree ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-vert-rfid-small.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />

                      <StaticImage
                          className={rfidFour ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-vert-rfid.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={rfidFive ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-horz-left-rfid-small.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={rfidSix ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-horz-right-rfid-small.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={rfidSeven ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-horz-left-rfid.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={rfidEight ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-body-horz-right-rfid.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spKeypadOne ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-key-stnd.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spKeypadTwo ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-key-stnd-mini.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spKeypadThree ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-key-vert.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spKeypadFour ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-key-vert-pull.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spKeypadFive ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-key-hoiz-left.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spKeypadSix ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-key-hoiz-right.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spKeypadSeven ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-key-hoiz-pull-left.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spKeypadEight ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-key-hoiz-pull-right.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spRfidOne ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-rfid-stnd-pull.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spRfidTwo ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-rfid-stnd.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spRfidThree ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-rfid-vert-mini.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spRfidFour ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-rfid-vert-pull.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spRfidFive ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-rfid-horiz-left.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spRfidSix ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-rfid-horiz-right.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spRfidEight ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-rfid-horiz-right-pull.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                      <StaticImage
                          className={spRfidSeven ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/aspire/aspire-smart-rfid-horiz-left-pull.png"
                          loading={'lazy'}
                          width={252}
                          height={252}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire Keypad"
                      />
                    </div>
                    <ProductDocuments productName={'aspire'}
                        brochure={brochure}
                        brochureLabel={t('download_brochure')}
                        lockLabel={bodyStyleLabel}
                        dataSheetLabel={t('download_datasheet')} dataSheet={dataSheet} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="section-delimeter container"/>
          <section className="page-benefits">
            <div className="container">
              <h1>
                <Trans>aspire_form_function</Trans>
              </h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div class="parent">
                    <div>
                      <h3 dangerouslySetInnerHTML={{__html: t('product_highlights')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('All_metal_housing')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Advanced_auto_unlock')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Slim_0.41_(10.35_mm)_profile')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Non_handed')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('mech_ada')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('IP55_rated,_FCC,_CE_and_IC_certified')}}/>
                      </ul>
                      <h3 dangerouslySetInnerHTML={{__html: t('Access_Options')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('keypad')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('rfid')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Mobile_Access')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('elec_key')}}/>
                      </ul>
                    </div>
                    <div>
                      <h3><Trans>cleo_functionality</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('Shared_Use_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Assigned_Use_Mode')}}/>
                      </ul>
                      <h3><Trans>cleo_smart_lock_functions</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('Parcel_Pickup_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Reservation_Mode')}}/>
                      </ul>
                      <h3><Trans>cleo_installation</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_1')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_3')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Snap_lock_cable_connector_(patented)_or_pin_connector')}}/>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={'locking-finish-items'}>
                  <div>
                    <h3><Trans>locking_options</Trans></h3>
                    <div className="thumbs">
                      <div>
                        <img
                            src={rearBolt}
                            width={128}
                            height={107} alt="bolt rear unit"/>
                        <p className={'curve-deadlatch'}><Trans>dual_use_deadbolt</Trans></p>
                      </div>
                      <div>
                        <img
                            className={'curve-rearlatch'}
                            src={rearLatch}
                            width={128}
                            height={107}
                            alt="cam rear unit"/>
                        <p className={'curve-deadlatch'}>
                          <Trans>auto_relock_deadlatch</Trans><br/>(<Trans>assigned_use_only</Trans>)</p>
                      </div>
                    </div>
                  </div>
                  <br/><br/>
                  <div>
                    <h3><Trans>finish_options</Trans></h3>
                    <div className="thumbs">
                      <div>
                        <img
                            src={swatchBrushed}
                            width={84}
                            height={84}
                            alt="brushed nickel finish"/>
                        <p className={'curve-swatch'}><Trans>brushed_nickel</Trans></p>
                      </div>
                      <div>

                        <img
                            src={swatchBlack}
                            width={84}
                            height={84}
                            alt="matte black finish"/>
                        <p className={'curve-swatch'}><Trans>matte_black</Trans></p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/><br/>
            <BookConsultation/>
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className="page-management">
            <BANManagement product={'Aspire'}/>
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className="faq-section">
            <FaqSection/>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
